<template>
  <div>
    <app-board v-for="(profile, key) in individualProfiles" :key="key" class="mb-5 p-3">
      <h5 class="mb-3">{{profile.label}} Profile</h5>
      <el-transfer
          @change="individualProfileModulesChanged($event, profile.value)"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="State Abbreviations"
          v-model="individualProfilesModules[profile.value]"
          :data="appModules">
      </el-transfer>
    </app-board>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: [],
      individualProfilesModules: null,
      appModules: [],
      individualProfiles: []
    }
  },
  beforeMount() {
    this.getIndividualProfiles()
    this.getAppModules()
  },
  methods: {
    getAppModules () {
      $store.dispatch('App-Module-api/getDropDownList').then( r => {
        r.forEach((module, index) => {
          this.appModules.push({
            label: module.label,
            key: module.value,
            customProperty: module.label
          });
        });
      })
    },
    getIndividualProfiles () {
      $store.dispatch('Individual-Profile-api/getDropDownList').then( r => {
        let individualProfilesModules = {}
        r.forEach( (profile, index) => {
          individualProfilesModules[profile.value] = []
          this.getIndividualProfileModules(profile.value)
        })
        this.individualProfilesModules = individualProfilesModules
        this.individualProfiles = r
      })
    },
    getIndividualProfileModules(profileId) {
      $store.dispatch('Individual-ProfileModule-api/getProfileModules', {id: profileId}).then( r => {
        this.individualProfilesModules[profileId] = r
      })
    },
    filterMethod(query, item) {
      return item.customProperty.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    individualProfileModulesChanged (event, profileId) {
      this.updateIndividualProfileModules(profileId, event)
    },
    updateIndividualProfileModules (profileId, modules) {
      let modulesIds = []
      for (let i in modules) {
        modulesIds.push(modules[i])
      }

      let apiParams = {
        id: profileId,
        data: {
          modulesIds: modulesIds
        }
      }

      $store.dispatch('Individual-ProfileModule-api/update', apiParams).then( r => {

      })
    }
  }
}
</script>

<style>
.el-transfer-panel {
  width: 350px !important;
  height: 400px;
}
.el-transfer-panel__list.is-filterable {
  height: 280px !important;
  padding-top: 0;
}
</style>
